import { Oauth2AccessToken } from '@/api/wexin-mp'

export default {
  namespaced: true,
  state: {
    openId: ''
  },
  actions: {
    oauth2AccessToken({ commit }, {
      fkOnlInst = '',
      code = ''
    } = {}) {
      return new Promise((resolve, reject) => {
        Oauth2AccessToken({
          fkOnlInst,
          code
        }).then(res => {
          commit('setOpenId', res.data.openid)
          resolve()
        }).catch(err => {
          reject(err)
        })
      })
    }
  },
  mutations: {
    setOpenId (state, openId) {
      state.openId = openId
    }
  }
}
