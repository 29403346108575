import { createRouter, createWebHistory, createWebHashHistory, createMemoryHistory } from 'vue-router'
import Setting from '@/setting'
import store from '@/store/index'
import util from '@/libs/util'

const routes = [
  {
    path: '/:fkOnlInst',
    name: 'peis-charge',
    meta: {
      title: '体检扫码付'
    },
    component: () => import('@/views/peis-charge')
  },
  {
    path: '/pay-result-wait',
    name: 'pay-result-wait',
    meta: {
      title: '支付结果'
    },
    component: () => import('@/views/pay-result-wait')
  },
  {
    path: '/pay-result',
    name: 'pay-result',
    meta: {
      title: '支付凭证'
    },
    component: () => import('@/views/pay-result')
  },
  {
    path: '/404',
    name: '404',
    meta: {
      title: '页面不存在'
    },
    component: () => import('@/views/error/404')
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/404'
  }
]

const router = createRouter({
  history: Setting.routerMode === 'history' ? createWebHistory(Setting.routerBase) : Setting.routerMode === 'hash' ? createWebHashHistory(Setting.routerBase) : createMemoryHistory(Setting.routerBase),
  routes
})

router.beforeEach((to, from, next) => {
  const browser = store.state.admin.browser.browser
  if (browser === 1) {
    const openId = store.state.admin.weixin.openId
    if (openId) {
      next()
      return
    }
    const code = to.query.code
    if (code) {
      next()
      return
    }
    const originUrl = window.location.origin
    const fullPath = to.fullPath
    // 保存当前路由地址，授权后还会跳到此地址
    sessionStorage.setItem('wxRedirectUrl', fullPath)
    if (to.params.fkOnlInst) {
      const appId = Setting.weixin[to.params.fkOnlInst].appId
      const redirectUrl = encodeURIComponent(originUrl + fullPath)
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect&connect_redirect=1`
    } else {
      next()
    }
  } else {
    next()
  }
})

router.afterEach(to => {
  util.title({
    title: to.meta.title
  })
  window.scrollTo(0, 0)
})

export default router
