import { createApp, h } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'vant/es/toast/style'
import 'vant/es/dialog/style'
import 'vant/es/notify/style'
import '@/styles/index.less'

import { Toast, Dialog, Notify } from 'vant'

const app = createApp({
  render: () => h(App),
  created () {
    this.$store.dispatch('admin/browser/judge')
  }
})

app.use(Toast)
app.use(Dialog)
app.use(Notify)

app.use(store)
app.use(router)
app.mount('#app')
