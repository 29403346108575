import axios from 'axios'
import Setting from '@/setting'
import { Dialog } from 'vant'

function errorModalShow (error) {
  Dialog.alert({
    title: '错误提示',
    message: '错误信息：' + error.message
  })
}

const service = axios.create({
  baseURL: Setting.request.apiBaseURL,
  timeout: 0
})

service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const dataAxios = response.data
    const { code } = dataAxios
    if (code === undefined || code === 200) {
      return dataAxios
    } else {
      const error = new Error(dataAxios.message)
      errorModalShow(error)
      throw error
    }
  },
  error => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = '请求错误'
          break
        case 401:
          error.message = '未授权，请登录'
          break
        case 403:
          error.message = '拒绝访问'
          break
        case 404:
          error.message = `请求地址出错: ${error.response.config.url}`
          break
        case 408:
          error.message = '请求超时'
          break
        case 500:
          error.message = '服务器内部错误'
          break
        case 501:
          error.message = '服务未实现'
          break
        case 502:
          error.message = '网关错误'
          break
        case 503:
          error.message = '服务不可用'
          break
        case 504:
          error.message = '网关超时'
          break
        case 505:
          error.message = 'HTTP版本不受支持'
          break
        default:
          break
      }
    }
    errorModalShow(error)
    return Promise.reject(error)
  }
)

export default service
