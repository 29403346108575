export default {
  namespaced: true,
  state: {
    browser: 0 // 浏览器类型，0：未知，1：微信，2：支付宝
  },
  actions: {
    judge ({ commit }) {
      const ua = window.navigator.userAgent.toLowerCase()
      if (ua.indexOf('micromessenger') > -1) {
        commit('judge', 1)
      } else if (ua.indexOf('alipay') > -1) {
        commit('judge', 2)
      }
    }
  },
  mutations: {
    judge (state, browser) {
      state.browser = browser
    }
  }
}
