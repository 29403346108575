import Setting from '@/setting'

const util = {}

/**
 * @description 更改标题
 * @param {Object} title 标题
 * @param {Object} count 未读消息数提示（可视情况选择使用或不使用）
 */
util.title = function ({ title, count }) {
  let fullTitle

  if (Setting.titleSuffix === false || Setting.titleSuffix === '') {
    fullTitle = title ? `${title}` : ''
  } else {
    fullTitle = title ? `${title} - ${Setting.titleSuffix}` : Setting.titleSuffix
  }

  if (count) fullTitle = `(${count}条消息)${fullTitle}`
  window.document.title = fullTitle
}

util.formatPhone = function (phone = '') {
  if (phone.length !== 11) {
    return phone
  }

  return phone.substr(0, 3) + '****' + phone.substr(phone.length - 4)
}

util.formatIDNumber = function (idNumber = '') {
  if (idNumber.length !== 18) {
    return idNumber
  }
  if (idNumber.length === 18) {
    return idNumber.substr(0, 4) + '***********' + idNumber.substr(idNumber.length - 3)
  }
}

function browserEnv () {
  const ua = window.navigator.userAgent.toLowerCase()
  return new Promise((resolve, reject) => {
    if (ua.indexOf('micromessenger') > -1) {
      resolve('weixin')
    } else if (ua.indexOf('alipay') > -1) {
      resolve('alipay')
    } else {
      reject(null)
    }
  })
}

export { browserEnv }

export default util
