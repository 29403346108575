const env = process.env.NODE_ENV

const Setting = {
  routerMode: 'history',
  routerBase: '/',
  titleSuffix: '',
  /**
   * 请求配置数据
   */
  request: {
    // 耿马正式库
    apiBaseURL: env === 'development' ? 'http://192.168.88.103:8700' : 'https://onl.gmxyy.com'
    // 沧源正式库
    // apiBaseURL: env === 'development' ? 'http://192.168.199.113:31601' : 'https://onl.cywzzzxrmyy.com'
  },
  weixin: {
    // 耿马正式库
    a: {
      appId: 'wx2a7560561d260140',
      payMerchantId: '53092600010002'
    },
    b: {
      appId: 'wx2a7560561d260140',
      payMerchantId: '53092600140005'
    },
    c: {
      appId: 'wx2a7560561d260140',
      payMerchantId: '53092600020002'
    },
    d: {
      appId: 'wx2a7560561d260140',
      payMerchantId: '53092600030002'
    },
    e: {
      appId: 'wx2a7560561d260140',
      payMerchantId: '53092600040002'
    },
    f: {
      appId: 'wx2a7560561d260140',
      payMerchantId: '53092600050002'
    },
    g: {
      appId: 'wx2a7560561d260140',
      payMerchantId: '53092600060002'
    },
    h: {
      appId: 'wx2a7560561d260140',
      payMerchantId: '53092600070002'
    },
    i: {
      appId: 'wx2a7560561d260140',
      payMerchantId: '53092600080002'
    },
    j: {
      appId: 'wx2a7560561d260140',
      payMerchantId: '53092600090002'
    },
    k: {
      appId: 'wx2a7560561d260140',
      payMerchantId: '53092600100002'
    },
    l: {
      appId: 'wx2a7560561d260140',
      payMerchantId: '53092600110002'
    },
    m: {
      appId: 'wx2a7560561d260140',
      payMerchantId: '53092600120002'
    }
    // 沧源正式库
    // a: {
    //   appId: 'wxefbe4f6629253c61',
    //   payMerchantId: '53092700010002'
    // },
    // b: {
    //   appId: 'wxefbe4f6629253c61',
    //   payMerchantId: '53092700020002'
    // },
    // c: {
    //   appId: 'wxefbe4f6629253c61',
    //   payMerchantId: '53092700030002'
    // },
    // d: {
    //   appId: 'wxefbe4f6629253c61',
    //   payMerchantId: '53092700040002'
    // },
    // e: {
    //   appId: 'wxefbe4f6629253c61',
    //   payMerchantId: '53092700050002'
    // },
    // f: {
    //   appId: 'wxefbe4f6629253c61',
    //   payMerchantId: '53092700060002'
    // },
    // g: {
    //   appId: 'wxefbe4f6629253c61',
    //   payMerchantId: '53092700070002'
    // },
    // h: {
    //   appId: 'wxefbe4f6629253c61',
    //   payMerchantId: '53092700080002'
    // },
    // i: {
    //   appId: 'wxefbe4f6629253c61',
    //   payMerchantId: '53092700090002'
    // },
    // j: {
    //   appId: 'wxefbe4f6629253c61',
    //   payMerchantId: '53092700100002'
    // },
    // k: {
    //   appId: 'wxefbe4f6629253c61',
    //   payMerchantId: '53092700110002'
    // },
    // l: {
    //   appId: 'wxefbe4f6629253c61',
    //   payMerchantId: '53092700120002'
    // },
    // m: {
    //   appId: 'wxefbe4f6629253c61',
    //   payMerchantId: '53092700130002'
    // },
    // o: {
    //   appId: 'wxefbe4f6629253c61',
    //   payMerchantId: '53092700140002'
    // }
  }
}

export default Setting
